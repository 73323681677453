<div class="question">
YASAL UYARI
</div>
<div class="description">
  Burada yer alan yatırım bilgi, yorum analiz ve tavsiyeleri yatırım danışmanlığı kapsamında değildir. Yatırım
  danışmanlığı hizmeti; aracı kurumlar, portföy yönetim şirketleri, mevduat kabul etmeyen bankalar ile müşteri arasında
  imzalanacak yatırım danışmanlığı sözleşmesi çerçevesinde sunulmaktadır. Burada yer alan yorum ve tavsiyeler, yorum ve
  tavsiyede bulunanların kişisel görüşlerine dayanmaktadır. Bu görüşler mali durumunuz ile risk ve getiri tercihlerinize
  uygun olmayabilir. Bu nedenle, sadece burada yer alan bilgilere dayanılarak yatırım kararı verilmesi beklentilerinize
  uygun sonuçlar doğurmayabilir.
</div>
<div class="btn-container">
  <button class="btn btn-outline-danger" (click)="onCloseClick()">
    {{noButton}}
  </button>
</div>
<div class="footer">
  © 2020 Copyright:<a style="margin-left: 5px; color: darkred" href="http://www.aifasoft.com/" target="_blank">AIFASOFT</a>
</div>
