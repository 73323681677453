export enum ROUTING {
  HOME = 'Anasayfa',
  PATENT = 'Patent-Listesi',
  GOLD = 'Fiyat-Ekrani',
  CURRENCY = 'Doviz',
  INFORMATION = 'Hakkimizda',
  CONTACT = 'İletisim',
  ERROR = 'Hata',
  KADSIS = 'Kad-sis',
  SHOP = 'Urunlerimiz'
}
