import {Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTING} from '../../shared/routing';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: {'(document:click)': 'onClick($event)'}
})
export class NavbarComponent implements OnInit {
  time = new Date();
  navbarOpen = false;
  isCollapsed = true;

  constructor(private router: Router,
              private _eref: ElementRef) {
  }

  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  route(page) {
    switch (page) {
      case 'home': {
        this.router.navigateByUrl(ROUTING.HOME);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'patent': {
        this.router.navigateByUrl(ROUTING.PATENT);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'gold': {
        this.router.navigateByUrl(ROUTING.GOLD);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'info': {
        this.router.navigateByUrl(ROUTING.INFORMATION);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'comm': {
        this.router.navigateByUrl(ROUTING.CONTACT);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'kadsis': {
        this.router.navigateByUrl(ROUTING.KADSIS);
        localStorage.setItem('selectedPage', page);
        break;
      }
      case 'shop': {
        this.router.navigateByUrl(ROUTING.SHOP);
        localStorage.setItem('selectedPage', page);
        break;
      }
    }

  }

  toggleNavbar() {
    if (this.navbarOpen) {
      this.closeNavbar();
    } else {
      this.openNavbar();
    }
  }

  onClick(event) {
    console.log(this._eref.nativeElement.contains(event.target));
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
    {
      this.openNavbar();
    }
  }

  closeNavbar() {
    this.navbarOpen = true;
  }

  openNavbar() {
    this.navbarOpen = false;
  }
}
