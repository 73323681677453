<section class="section-bg">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="section-title">
          <h2>ÜRÜNLERİMİZ</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <a href="https://www.trendyol.com/magaza/sahap-kuyumculuk-m-140725?sst=0">
            <img
              src="../../assets/img/products/trendyol.jpeg"
              alt="">
            </a>
          </div>
          <div class="product-title">
            <h3><a>Trendyol Mağazamız</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.trendyol.com/magaza/sahap-kuyumculuk-m-140725?sst=0" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <a href="https://www.n11.com/magaza/sahapkuyumcu">
            <img
              src="../../assets/img/products/n11.jpeg"
              alt="" style="height: 9.5rem; width: 100%;">
            </a>
          </div>
          <div class="product-title">
            <h3><a>n11.com Mağazamız</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.n11.com/magaza/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <a href="https://www.hepsiburada.com/sahapkuyumcu">
            <img
              src="../../assets/img/products/hespsiburda.jpg"
              alt="" style="width: 100%; height: 9.5rem;">
            </a>
          </div>
          <div class="product-title">
            <h3><a>Hepsiburada Mağazamız</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.hepsiburada.com/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <img
              src="../../assets/img/products/kilitli.jpg"
              alt="">
          </div>
          <div class="product-title">
            <h3><a>22 Ayar Adana Burması Bilezik</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.n11.com/magaza/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <img
              src="../../assets/img/products/kibritcopu.jpg"
              alt="">
          </div>
          <div class="product-title">
            <h3><a>22 Ayar Kibrit Çöpü Bilezik</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.n11.com/magaza/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <img
              src="../../assets/img/products/tarsus.jpg"
              alt="">
          </div>
          <div class="product-title">
            <h3><a>22 Ayar Tarsus Burması Bilezik</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.n11.com/magaza/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <img
              src="../../assets/img/products/gram.jpg"
              alt="">
          </div>
          <div class="product-title">
            <h3><a>22 Ayar 1 Gram Altın</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.n11.com/magaza/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div class="single-product">
          <div class="product-thumb">
            <img
              src="../../assets/img/products/kulcegumus.png"
              alt="">
          </div>
          <div class="product-title">
            <h3><a>1000g 999.0 Külçe Gümüş</a></h3>
          </div>
          <div class="product-btns">
            <a href="https://www.n11.com/magaza/sahapkuyumcu" target="_blank" class="btn-round mr-2"><i class="fa fa-shopping-cart"></i></a>
          </div>
        </div>
      </div> -->
    </div>

  </div>

</section>
