import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-live-exchange',
  templateUrl: './live-exchange.component.html',
  styleUrls: ['./live-exchange.component.scss']
})
export class LiveExchangeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
