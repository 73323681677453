<div class="rss-cont">
  <table class="table table-bordered table-hover">
    <tr *ngFor="let item of xmlItems">
      <td>
        <a href={{item.link}} target="_blank">
          <div class="link">
            {{item.title}}
          </div>
        </a>
      </td>
    </tr>
  </table>
</div>
