import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import xml2js from 'xml2js';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html',
  styleUrls: ['./rss.component.scss']
})
export class RssComponent implements OnInit {
  url: string = 'http://89.19.27.83:7575/https://tr.investing.com/rss/news.rss';
  public xmlItems: any;
  header: HttpHeaders = new HttpHeaders().set('Content-Type', 'text/xml');

  constructor(private _http: HttpClient) {
  }

  ngOnInit(): void {
    this.loadXML();
  }

  loadXML() {
    this._http.get(this.url, {headers: this.header, responseType: 'text'})
      .subscribe((data) => {
        this.parseXML(data)
          .then((data) => {
            this.xmlItems = data;
          });
      });
  }

  parseXML(data) {
    return new Promise(resolve => {
      let k: string | number,
        arr = [],
        parser = new xml2js.Parser(
          {
            trim: true,
            explicitArray: true
          });
      parser.parseString(data, function(err, result) {
        let obj = result;
        for (k in obj.rss.channel[0].item) {
          let data = obj.rss.channel[0].item[k];
          arr.push({
            title: data.title[0],
            pubDate: data.pubDate[0],
            link: data.link[0]
          });
        }
        resolve(arr);
      });
    });
  }
}
