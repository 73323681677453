<div class="kadsis-container">
  <div class="container">
    <img class="img-fluid pb-lg-3" style="height: 80px;" src="../../assets/img/logo-white.svg" alt="KAD-SİS">
    <div class="info">
      <div class="text-icon">
        <div class="text1 col-sm-12 col-md-12 col-lg-12">
          <div class="header">
            <h4 class="title">YASTIK ALTINDA DEĞİL GÜVEN ALTINDA</h4>
            <h5 class="title">Sisteme dahil seçkin kuyumcular aracılığı ile altınlarınızı artık haftanın her günü altın
              hesabınıza aktarabilirsiniz. Elinizde bulundurduğunuz altınlarınız, çalınma ve kaybolma riski olmadan
              banka hesabınızda altın olarak güvende tutulur.</h5>
          </div>
          <p class="paragraph"> Elinizde bulundurduğunuz altınlarınızı sisteme dahil seçkin kuyumcular aracılığı ile
            haftanın her günü banka hesabınıza kolayca ve güvenle aktarmanızı sağlayan bir sistemdir. Yastık altındaki
            altınlarınızı banka hesabınıza yatırmak için, belirli bir gün de belirli bir banka şubesinde altın kabul
            günü
            olmasını beklemeden, sisteme dahil seçkin kuyumculara, yani size en yakın Altın Değerleme Noktası (ADN)’na
            giderek, altınlarınızın güvenli bir şekilde değerlemesini yaptırabilir ve anında sistem içerisindeki banka
            hesabınıza 24 ayar karşılığı gram cinsinden hiçbir masraf ödemeden aktarabilirsiniz.

            Türkiye'de ilk defa 2010 yılında İAR öncülüğünde başlatılmış olan Altın Bankacılığı süreci, anlaşmalı
            bankalar
            ile devam ederken, KADSİS aracılığı ile istediğiniz gün sisteme dahil olan seçkin kuyumcular aracılığıyla
            altınlarınızı banka hesabınıza güvenle aktarabilirsiniz.</p>
        </div>
      </div>
    </div>
  </div>
  <img style="width: 100%" src="assets/img/kadsis.jpg">
  <div class="container">
    <div class="info">
      <div class="text-icon">
        <div class="text1 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <h4 class="mb-4">KAD-SİS İŞLEYİŞ</h4>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>01</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">BİLGİLENDİRME FORMU</h6>
                <p class="p-3">Bilgilendirme Formu'nu okuyup imzalayınız.</p>
              </div>
            </div>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>02</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">KADSİS ONAY KODU</h6>
                <p class="p-3">KADSİS'den gelen SMS ONAY KODU'nu sisteme giriniz.</p>
              </div>
            </div>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>03</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">BANKA ONAY KODU</h6>
                <p class="p-3">Bankanızın göndereceği SMS ONAY KODU'nu giriniz.</p>
              </div>
            </div>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>04</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">ALIM TESLİMİ</h6>
                <p class="p-3">ADN yetkilisine teslim edeceğiniz altınlar kamera kaydı altında kontrol edilerek
                  değerlemesi yapılacaktır.</p>
              </div>
            </div>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>05</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">BANKAYA TRANSFER ONAY KODU</h6>
                <p class="p-3">ADN yetkilisi ile mutabıksanız, KADSİS'den gelen bankaya transfer için SMS ONAY KODU'nu
                  sisteme giriniz.</p>
              </div>
            </div>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>06</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">TESLİM TESELLÜM</h6>
                <p class="p-3">Sistemin ürettiği teslim tesellüm tutanağını imzalayanız ve bir nüshasını kendinize
                  alınız.</p>
              </div>
            </div>
            <!-- feature item -->
            <div class="bg-color py-5 px-4 box-shadow transition hover-bg-dark col-12">
              <div class="icon-bg mb-4 water-wave number-box">
                <span>07</span>
              </div>
              <div class="kadsis-info">
                <h6 class="p-3">TEBRİKLER</h6>
                <p class="p-3">Altınlarınız banka hesabınıza geçmiştir. Bunu teyit eden bilgilendirme SMS'i bankanızdan
                  gelecektir. <br>
                  Artık siz de altınlarınızı banka hesabınızda güvenle saklayabilir ve gelir elde edebilirsiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <iframe width="100%" height="500" src="https://www.youtube.com/embed/ieJ5DoPtV6I" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
</div>
