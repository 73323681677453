<div class="contact-container">
  <div class="contact-form col-sm-12 col-md-12 col-lg-7">
    <h1 class="title">Bize Ulaşın</h1>
    <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
      <div class="form-group">
        <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control"
               placeholder="İsim Soyisim"
               required #name="ngModel">
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
          <div *ngIf="name.errors.required">
            İsim Gereklidir
          </div>
        </div>
      </div>
      <div class="form-group">
        <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" placeholder="E-Mail"
               required email #email="ngModel">
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
          <div *ngIf="email.errors.required">E-mail Gereklidir</div>
          <div *ngIf="email.errors.email">E-mail, geçerli bir e-mail adresi olmalıdır.</div>
        </div>
      </div>
      <div class="form-group">
        <input type="text" name="phone" id="phone" [(ngModel)]="model.phone" class="form-control" placeholder="Telefon">
      </div>
      <div class="form-group">
        <textarea style="height:139px;" name="message" id="message" [(ngModel)]="model.message" rows="5" class="form-control"
                  placeholder="Message" required #message="ngModel"></textarea>
        <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
          <div *ngIf="message.errors.required">Mesaj Gereklidir</div>
        </div>
      </div>
      <div class="form-group" style="text-align: end;">
        <button [disabled]="!contactForm.form.valid" class="btn btn-success">Gönder</button>
      </div>
    </form>
  </div>
  <div class="maps-form col-sm-12 col-md-12 col-lg-5">
    <div class="tel-social">
      <div class="tel col-sm-12 col-md-4 col-lg-4">
        <h5 class="title">Telefon</h5>
        <p class="subtitle">+90 322 360 0060</p>
        <h5 class="title">E-posta</h5>
        <p class="subtitle">info@sahapkuyumcu.com.tr</p>
      </div>
    </div>
    <div class="address">
      <h5 class="title">Adres</h5>
      <p class="map-text">Tepebağ Mahallesi Çakmak Caddesi Hilalhan İş Merkezi No2/Y Seyhan / Adana</p>
    </div>
  </div>
  <div class="map-container col-12">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12747.578567582683!2d35.32536!3d36.988448!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9c63630d13e0983e!2s%C5%9Eahap+Kuyumcu!5e0!3m2!1str!2str!4v1470391316366"></iframe>
  </div>
</div>
