<div class="about-container">
    <div class="info">
        <div class="container">
            <div class="text col-sm-12 col-md-12 col-lg-12">
                <h3 class="title1">HAKKIMIZDA</h3>
                <p class="info-paragraph">Adana'da Milli Mücahit olan Şeyh Cemil Nardalı Efendinin birinci oğlu olan Mesut Nardalı, Yıldız Sarrafiye Evini 1930 yılında kurmuştur.1957 yılında Mesut Nardalı’nın oğlu Şahap Nardalı, Yıldız Sarrafiye Evi'nin ünvanını Şahap Kuyumcu
                    olarak değiştirmiştir. Şahap Kuyumcu'yu da 1957-1995 yıllarında oğlu Volkan Nardalı’ya devretmiştir, Şahap Kuyumcu geçmişten bugüne nesilden nesile aktarılan başarısıyla günümüze kadar gelmiş ve daha sonraki nesillere aktarılarak başarılarına
                    devam edecektir. Şahap kuyumcu, altın sektörünün dinamiklerini en iyi şekilde analiz ederek sektörde hızla yükselmiş, güvenilir kurum kimliğini koruyarak, müşterilerinin ihtiyaç, talep ve beklentilerini ilkeleri ve etik ticari koşullar
                    doğrultusunda karşılamıştır.Şahap Kuyumcu, 1930 yılından günümüze kadar istihdamın artmasına katkı sağlayarak,çevresine faydalı bir kurum olmaya devam edecektir…</p>
            </div>
        </div>
    </div>
</div>
<section id=timeline>
    <div class="demo-card-wrapper">
        <div class="demo-card demo-card--step1">
            <div class="head">
                <div class="number-box">
                    <span>01</span>
                </div>
                <h2><span class="small">Şahap Kuyumcu</span> MİSYONUMUZ</h2>
            </div>
            <div class="body">
                <p>Şahap Kuyumcu markasını sektörün en üst seviyelerine getirmek için müşterilerimizin beklentilerini en yüksek düzeyde karşılayacak hizmeti vermek temel felsefemiz ve hedefimizdir.</p>
            </div>
        </div>
        <div class="demo-card demo-card--step2">
            <div class="head">
                <div class="number-box">
                    <span>02</span>
                </div>
                <h2><span class="small">Şahap Kuyumcu</span> VİZYONUMUZ</h2>
            </div>
            <div class="body">
                <p>Sektöre altın arzı sunan bir işletme olarak devam edeceğine inandığımız başarımızın markalaşarak yurtiçi ve dışındaki arz ve talebi karşılayan sektörün lider ismi olmak.</p>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid">
    <div class="container">
        <app-carousel [slides]="aboutSlide" [animationType]="animationType"></app-carousel>
    </div>
</div>
<div class="container-tse">
    <div class="container">
        <div class="col-sm-12 col-md-12 col-lg-6">
            <img [src]="src1" width="100%">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <img [src]="src2" width="100%">
        </div>
    </div>
</div>