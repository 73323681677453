import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import AOS from 'aos';
import {Slide} from '../_components/carousel/carousel.interface';
import {CarouselComponent} from '../_components/carousel/carousel.component';
import {AnimationType} from '../_components/carousel/carousel.animation';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  src1: string = 'assets/img/aboutSlide/markatescil.jpg';
  src2: string = 'assets/img/aboutSlide/tsebelgesi.jpg';
  animationType = AnimationType.Fade;

  animationTypes = [
    {
      name: 'Fade',
      value: AnimationType.Fade
    }
  ];
  aboutSlide: Slide[] = [
    {
      headline: '',
      src:
        '../../assets/img/aboutSlide/sahap2.jpg'
    }, {
      headline: '',
      src:
        '../../assets/img/aboutSlide/sahap3.jpg'
    }, {
      headline: '',
      src:
        '../../assets/img/aboutSlide/sahap4.jpg'
    }, {
      headline: '',
      src:
        '../../assets/img/aboutSlide/sahap5.jpg'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }
}
