import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {SocketData} from '../_models/socketData';
import {CATEGORYTYPE} from '../_models/categoryType';
import {HomeProvider} from '../_services/home.provider';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
