<div class="container">
  <div class="home-container col-12">
    <div class="patent">
      <table>
        <thead>
        <tr>
          <th style="width: 13px;">
            <div class="mavi"></div>
          </th>
          <th>
            <div class="mavi">AD SOYAD</div>
          </th>
          <th>
            <div class="mavi"></div>
          </th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td data-label="#">
            <div class="mavi">1</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sadettin GEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A30</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">2</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali USKANER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A31</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">3</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Haşim Nuri MAVRUK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A32**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">4</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yılmaz BOLLUK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A33*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">5</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İhsan ERKE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A34*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">6</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Edip Aytaç KENDİRCİOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A35**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">7</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet PORTAKALCI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A36**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">8</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cevdet BEKEN- Erdinç BEKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A37</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">9</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zihni ÖZDOĞDU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A38**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">10</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet İLK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A39*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">11</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İsmail URASLI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A40**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">12</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yasin SEZER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A41*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">13</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şaban GÜNAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A42*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">14</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal GÜNAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A43*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">15</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fethi TOKMAKLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A44**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">16</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ömer – Mehmet Sami GEÇİOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A45</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">17</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gökhan BİRCAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A46**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">18</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selçuk BALCIOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A47**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">19</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özhan KIZILKAYA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A48</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">20</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">R. Uğur DEĞİRMENCİOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A49</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">21</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Emre ALTINÖREN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A50**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">22</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Necati KANBER – Cevdet KANBER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A51</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">23</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Necati ASLANGÖREN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A52*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">24</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Seyfi GÜNAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A53*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">25</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nevzat ALTINDÖKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A54**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">26</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nursel ALTINDÖKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A55*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">27</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kenan BALTACI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A56**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">28</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şenol HALİSKARANFİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A57</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">29</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Onur ELMA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A58*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">30</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Vedat GÜLÜMSEK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A59*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">31</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali SEZER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A60**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">32</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özden SAYLIK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A61</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">33</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Celal SÜREKLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A62</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">34</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet KAYAS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A63**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">35</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">A. Turan ERDEMİR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A64</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">36</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet CÖMERTPAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A65*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">37</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver ÜNVER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A66</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">38</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hikmet BAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A67</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">39</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mecit DEĞİŞMEZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A68*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">40</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yusuf YASAVUR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A69**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">41</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hamdi TAVRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A70*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">42</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet-Mahmut ARICIOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A71</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">43</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Muhsin SÖNMEZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A72*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">44</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şevket ÇİÇEKÇİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A73*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">45</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nebil LEVENT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A74*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">46</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sefa NARDALI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A75**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">47</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cevdet KANBER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A76*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">48</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cahit AYGÜN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A77*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">49</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7"></div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A78**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">50</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yalgın TALTEKİN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A79*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">51</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nadir ALTINSOY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A80</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">52</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hakan KORKMAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A81**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">53</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Edip DEVECİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A82*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">54</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Aydın ALTINBÜKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A83</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">55</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Vedat GAZOZCU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A84**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">56</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yusuf İNCEKALAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A85</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">57</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bircan ÇİMET</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A86*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">58</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Salih ELMASSATAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A87**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">59</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Esat ORTATEPE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A88**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">60</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mahmut SEYHAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A89*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">61</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ziya KATLAV</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A90**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">62</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Münir ÇİÇEKÇİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A91*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">63</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ferit YIKIN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A92**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">64</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali USKANER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A93**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">65</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim HOROZGİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A94*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">66</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sadık GÖKÇELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A95**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">67</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mahir KORKMAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A96**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">68</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fahriye CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A97*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">69</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yusuf BEYAZBULUT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A98**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">70</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim GÖKESMER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">A99**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">71</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bahattin KINACI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B30**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">72</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cahit GEZER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B31**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">73</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet İzzet AKDENİZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B32**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">74</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal GEZER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B33**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">75</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Necmi DERAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B34*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">76</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zeki ALTINSOY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B35*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">77</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet AYHAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B36**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">78</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Semih GÖKÇELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B37**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">79</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet SAYIN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B38**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">80</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süreyya DİLMAÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B39*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">81</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Orhan ÜNVAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B40**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">82</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ferit KIYAĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B41**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">83</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mithat DAĞTEKİN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B42**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">84</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mesut SAYGILI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B43**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">85</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Galip KARAKUŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B44*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">86</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Aydın ESKİDELVAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B45*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">87</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal TATLI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B46*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">88</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Musa KARAYTU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B47**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">89</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selim ÖZKAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B48**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">90</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Naci GÖZÜKÜÇÜK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B49*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">91</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ruhettin TOKMAKLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B50*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">92</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver Yücel ÖZALEVLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B51</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">93</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali BİRDİR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B52*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">94</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nadir VURANKAYA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B53**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">95</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yunus KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B54**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">96</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Salih Niyazi BOĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B55</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">97</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdoğan EREN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B56**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">98</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sinan SAKLIYAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B57**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">99</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali YILDIRIM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B58**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">100</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet Zeki TAYLAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B59*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">101</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hamdi SAVCI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B60**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">102</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Celalettin YENGÜNER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B61**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">103</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Melih ÜÇÖZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B62**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">104</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sefa BALTACI – Serdar BALTACI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B63</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">105</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selahattin DERSE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B64*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">106</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şahabettin NARDALI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B65</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">107</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sabit SEVİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B66*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">108</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal LEVENTELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B67**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">109</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Duygu ŞENYÜREK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B68**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">110</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hayrettin DELİBAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B69**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">111</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet SAYILGAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B70**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">112</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Muzaffer SOFUOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B71**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">113</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver AYDOĞAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B72*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">114</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Niyazi BALLIOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B73</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">115</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Alparslan ŞENER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B74*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">116</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Orhan SERİN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B75 **</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">117</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Behzat COŞAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B76**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">118</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yunus Yasin BOĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B77</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">119</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ethem ŞENKURT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B78*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">120</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet ŞENDAĞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B79**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">121</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver ÖZELAKÇİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B80**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">122</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bahattin TANGELLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B81*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">123</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Reşit SERTTAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B82**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">124</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zihni BÜYÜKNİSAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B83</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">125</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Uğur SINMAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B84**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">126</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet SAYIS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B85*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">127</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Halil TANIŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B86</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">128</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal ÖZDOLAP</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B87*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">129</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Musa CAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B88*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">130</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Kasım ATAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B89**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">131</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yusuf GÜRÇINAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B90**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">132</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Adnan MENGİ Latif MENGİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B91</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">133</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Atilla ÖZNERGİZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B92**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">134</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sebahattin ŞENDAĞ – Umut CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B93</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">135</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Burhan FİDAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B94**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">136</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nuri PAZARBAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B95*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">137</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hasan Hamdi MEMİLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B96**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">138</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7"></div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B97**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">139</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kahraman DELİBALTA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B98**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">140</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yalçın ÖZELKÖSE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">B99**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">141</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nebil CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C30*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">142</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Can KAYAS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C31**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">143</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mahmut SÖNMEZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C32**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">144</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mansur TAVRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C33*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">145</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cengiz BULLUK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C34**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">146</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yücel BİLDİREN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C35*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">147</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İzzet ERSAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C36*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">148</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özdemir RAHATLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C37*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">149</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ersin SAYGIDEĞER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C38</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">150</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süleyman BOĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C39*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">151</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ercan BÜYÜKNİSAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C40</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">152</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Metin MARİS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C41*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">153</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gülten AKPINAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C42</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">154</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali GEZGİNCİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C43*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">155</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Asıf İNCEKALAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C44*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">156</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">V. Selim MUTLUER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C45</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">157</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fahri BEKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C46*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">158</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet KAYAS- Eniz KAYAS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C47</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">159</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şahap KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C48**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">160</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7"></div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C49**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">161</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Tekin ÖNEN-Tuğay DEĞİRMENCİGİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">C50</div>
          </td>
        </tr>

        <tr class="style2">
          <td class="style2" data-label="#">
            <div class="style"></div>
          </td>
          <td class="style2" data-label="Ad Soyad">
            <div class="style" id="number7">C-51 VE C-99 ARASI PATENTLER ODAMIZ</div>
          </td>
          <td class="style2" data-label="">
            <div class="style" id="number8"></div>
          </td>
        </tr>

        <tr class="style2">
          <td class="style2" data-label="#">
            <div class="style"></div>
          </td>
          <td class="style2" data-label="Ad Soyad">
            <div class="style" id="number7">TARAFINDAN VERİLMEMİŞTİR.</div>
          </td>
          <td class="style2" data-label="">
            <div class="style" id="number8"></div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">162</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ufuk BALEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D30**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">163</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Suat YÜKSEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D31**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">164</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Necati NOMANOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D32</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">165</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şaban YEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D33**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">166</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İrfan CİHANBEYLİ – Hakan DEĞİŞGEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D34</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">167</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Semir DABBAĞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D35*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">168</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Murat GÜLAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D36*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">169</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İsmail Sefa BIÇAKÇI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D37*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">170</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D38*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">171</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Kemal MAT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D39**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">172</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cem AKÇİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D40**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">173</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sezai BÜYÜKSANDIÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D41**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">174</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cavit ZERCERTAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D42**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">175</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdoğan ERTİT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D43*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">176</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fatih İNCEKALAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D44</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">177</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cemal UZMAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D45**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">178</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özel İNCEKALAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D46**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">179</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selim HEÇUYUMAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D47**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">180</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nebil KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D48*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">181</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Volkan KAYAS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D49</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">182</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Levent TEKGÜL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D50*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">183</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemalettin YAPTI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D51**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">184</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süleyman EKİCİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D52**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">185</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erol DEVECİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D53</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">186</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ayhan LÜYE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D54</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">187</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sabri AYDOĞAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D55*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">188</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Eyüp ŞAHBAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D56**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">189</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim İNANOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D57*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">190</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cüneyt HÜR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D58**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">191</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Emin EVRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D59</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">192</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal LEVENTELİ- Ahmet LEVENTELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D60**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">193</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Niyazi GEZER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D61**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">194</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Orhan KÖSE Murat Vedi KÖSE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D62</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">195</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Tekin RAHATLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D63*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">196</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İsa ŞENTÜRK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D64**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">197</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Semih TURAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D65*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">198</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet AYDOĞAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D66*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">199</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Aydın NURALIŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D67*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">200</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet ÖZÜSAĞLAM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D68*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">201</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdinç TAVRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D69</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">202</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özer ELMA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D70**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">203</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">M. Ali ESER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D71*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">204</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erol M. TOKGÖZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D72**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">205</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yahya BOĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D73</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">206</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özcan KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D74*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">207</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Suat BOĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D75*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">208</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Oktay UÇMAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D76**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">209</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sedat LEVENTELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D77</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">210</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Adnan KURUMOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D78**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">211</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Orhan FİDAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D79**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">212</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yaşar DEMİRBAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D80</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">213</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gültekin CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D81*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">214</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sami KÜÇÜKALTIN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D82**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">215</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Muhsin DERAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D83</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">216</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şevket BIÇAKÇI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D84*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">217</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cem DİNÇEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D85*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">218</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Oktay RSAN- Aşkın ERSAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D86</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">219</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bedii ÖZALEVLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D87*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">220</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali AĞLAGÜ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D88*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">221</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdoğan RAHATLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D89*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">222</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yılmaz FINDIKKIRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D90*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">223</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali ÖNENTAŞÇI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D91**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">224</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Turhan YÜZEREROĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D92</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">225</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ayhan BALTACI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D93**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">226</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özcan SÜSLÜ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D94**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">227</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Can BEKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D95</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">228</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Murat ATALAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D96</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">229</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Göksel TANOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D97</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">230</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Uğur YASAVUR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D98*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">231</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zeki GEÇGEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">D99**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">232</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şadan FINDIKKIRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E30</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">233</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Çetin SERİN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E31**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">234</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mustafa ÖZERDEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E32</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">235</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gökhan AZİZOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E33*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">236</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erol KAPTIKAÇTI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E34</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">237</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yahya İNCEKALAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E35**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">238</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Sezgin ŞİRE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E36**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">239</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Tamer Öztan YAPTI-Yıldıray BESLER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E37*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">240</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ercan LEVENTKAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E38</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">241</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet BÜYÜKEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E39*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">242</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Vedat GÜLÜMSEK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E40**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">243</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selahattin ÇEKİLDEM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E41**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">244</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bayram ÇOBAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E42**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">245</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Orhan ERSAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E43**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">245</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Adnan UZKANBAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E44</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">247</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kenan RAHATLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E45*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">248</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süleyman ÖZONUR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E46**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">249</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Tuncay ESİN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E47**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">250</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet MAT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E48**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">251</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nejdet İNCEKAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E49**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">251</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İskender ŞİMŞEK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E50*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">252</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Emin IŞIL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E51*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">253</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hasan MİREL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E52</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">254</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali SEVİMLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E53*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">255</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Salih KÖKÜ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E54**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">256</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet FETTULLAHOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E55</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">257</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Metin TAVRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E56*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">258</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Oral AKAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E57*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">259</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Metin AKYÜZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E58*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">260</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mahmut BİLDİREN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E59</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">261</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim ÖZGÜZEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E60**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">262</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özcan KAYAS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E61**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">263</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bülent CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E62*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">264</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Alaaddin ÇULHA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E63*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">265</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ergin CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E64**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">266</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim ARSLANGÖREN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E65*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">267</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selahattin TOKMAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E66**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">268</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fikret YÜNLÜEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E67*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">269</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erol ÖZKELEŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E68</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">270</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Rıdvan ORUÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E69*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">271</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süleyman EĞRİSAKAL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E70*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">272</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ümit DOĞAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E71**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">273</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nadir KARAYTUĞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E72**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">274</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bülent KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E73*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">275</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şenol HALİSKARANFİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E74*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">276</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Demir KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E75*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">277</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Levent DOĞRULUK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E76*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">278</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Levent NERSE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E77**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">279</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ruhan TIĞRAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E78**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">280</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Esat ORTAÇBAYRAM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E79**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">281</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nevzat KÜÇÜKGÖDE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E80**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">282</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İsmail YEŞİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E81*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">283</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Timur KARAYAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E 82*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">284</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özen TAVRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E83*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">285</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet YAZAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E84**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">286</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehemt H. AKKOÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E85**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">287</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Salim ÜÇKAYABAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E86*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">288</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Can UĞURLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E87**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">289</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İhsan ÖZLÜKOL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E88*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">290</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zihni ÖZBİÇER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E89**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">291</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yalçın TÜREMİŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E90</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">292</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Muhittin BÜLBÜL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E91</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">293</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İsmail KEKLİKÇİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E92*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">294</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şahin GÜLER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E93**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">295</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Uğur SANRI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E94**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">296</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver YEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E95**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">297</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Abdulbari ŞEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E96</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">298</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İlker ÜÇKAYABAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E97**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">299</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Göksel AVCI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E98*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">300</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali MAVRUK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">E99**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">301</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Necati KAYGUSUZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F30*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">302</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sedat HÜSRAFOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F31**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">303</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Z. Murat SABANCIOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F32*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">304</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cüneyt TEMİZKAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F33</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">305</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Murat Şevki DEMİRKIRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F34**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">306</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Nuri BOĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F35</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">307</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gazi Murat SANGÜN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F36**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">308</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdem ÖZALEVLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F37**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">309</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mustafa MALGİT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F38*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">310</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmeh BILDIRCIN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F39</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">311</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sedat LUNKAYA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F40*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">312</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hidayet AYAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F41</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">313</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Aziz ESKİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F42**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">314</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Murat ÜNLÜARICI- Serdal GÜLAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F43**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">315</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim KAÇAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F44**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">316</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet EZGÜ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F45</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">317</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erol TANIŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F46</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">318</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">M.Ali ÜNALGAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F47**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">319</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Cumali TAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F48*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">320</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet AYAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F49**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">321</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süreyya DİLMAÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F50*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">322</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mustafa GEÇSEK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F51**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">323</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mustafa Nadir GÖĞÇE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F52**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">324</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet AKDENİZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F53*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">325</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ethem ADIYEKE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F54</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">326</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Oktay YILDIRIM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F55</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">327</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ekrem SÜREKLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F56</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">328</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet KAHYALAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F57</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">329</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Aziz SABANCIOĞULLARINDAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F58</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">330</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İsmail AKGÜL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F59**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">331</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Suat TOKLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F60**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">332</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sefa DALGINLI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F61**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">333</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sabri ANDIZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F62</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">334</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Oktay KIYAĞA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F63</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">335</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali DEMİRKIRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F64</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">336</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sabri CİVANOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F65**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">337</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Necati COŞAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F66*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">338</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mithat BAŞTUĞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F67**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">339</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Şahin ESKİLER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F68</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">340</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özcan DEMİR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F69</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">341</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Aydın GİZİR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F70*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">342</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ogün ÖZARSLAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F71</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">343</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Coşkun AYGÜN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F72*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">344</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Caner SAYILGAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F73**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">345</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ayhan SEZENCAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F74</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">346</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Behzat MAVRUK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F75</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">347</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fatih TALAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F76**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">348</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ferit SEMERCİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F77</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">349</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bedir SERİN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F78**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">350</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sedat MEZARCI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F79*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">351</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal ŞENDAĞ-Güven AYRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F80</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">352</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim ÖZGÜNSÜR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F81*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">353</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süleyman GÖK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F82</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">354</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet KARAYTUĞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F83**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">355</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdem ÖZKILINÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F84</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">356</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Metin EVSEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F85**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">357</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Selim ULUÇAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F86**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">358</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet ULUÇAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F87**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">359</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali DİK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F88**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">360</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bülent ATAKAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F89</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">361</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet MERT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F90</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">362</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Uğur ÖZALP</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F91*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">363</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yalçın BÜYÜKYEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F92</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">364</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gökhan BAŞTUĞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F93</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">365</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Serhat DOĞAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F94</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">366</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Kemal YÜREK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F95*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">367</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Meriç SAĞLAMTUNDU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F96</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">368</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Çetin AKŞAM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F97</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">369</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Çağlayan – Çınar UZKANBAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F98</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">360</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Levent HALLAÇOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">F99</div>
          </td>
        </tr>

        <tr class="style2">
          <td class="style2" data-label="#">
            <div class="style"></div>
          </td>
          <td class="style2" data-label="Ad Soyad">
            <div class="style" id="number7">G-30 VE G-99 ARASI PATENTLER ODAMIZ</div>
          </td>
          <td class="style2" data-label="">
            <div class="style" id="number8"></div>
          </td>
        </tr>

        <tr class="style2">
          <td class="style2" data-label="#">
            <div class="style"></div>
          </td>
          <td class="style2" data-label="Ad Soyad">
            <div class="style" id="number7">TARAFINDAN VERİLMEMİŞTİR.</div>
          </td>
          <td class="style2" data-label="">
            <div class="style" id="number8"></div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">361</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet Ayhan AKŞAM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H30*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">362</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Muhammed KÜÇÜKOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H31*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">363</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Çetin TUNÇKAYA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H32**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">364</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gazi AKYOL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H33</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">365</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sedat PEKER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H34</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">366</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yunus ONATOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H35*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">367</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ferhat YALMAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H36</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">368</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hasan ÖRGEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H37</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">369</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mustafa CAVLAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H38</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">370</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Süleyman ÜSTÜNGÖR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H39</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">371</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Rüçhan BOZYİĞİT</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H40*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">372</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim Fatih KAZANCI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H41</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">373</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Koray DİLMAÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H42**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">374</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali ESKİL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H43**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">375</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Edip ASAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H44</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">376</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali MENGİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H45</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">377</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdal BALTACI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H46</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">378</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gani ÖTÜNÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H47**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">379</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim AYRAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H48</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">380</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Tarkan TÜRK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H49</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">381</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zahir CIZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H50</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">382</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Serkan UĞURLUGÜLBÜKEN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H51</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">383</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Coşkun AYGÜN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H52</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">384</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hasan KISA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H53*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">385</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erol Emre MANSUROĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H54*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">386</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Uğur LEVENTELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H55</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">387</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yüksel TALAY</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H56**</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">388</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim SEMİRLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H57</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">389</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Can ONATOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H58</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">390</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Mehmet Can EROĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H59</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">391</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Nihat UZKANBAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H60</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">392</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Zeki SAYGIDEĞER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H61</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">393</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Soner ARIDAŞIR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H62</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">394</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Salim ÜÇKAYABAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H63*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">395</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Sercan ŞİRE</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H64</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">396</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hakan ESKİCİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H65</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">397</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hakan SUMSAL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H66</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">398</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bülent ESKİLER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H67</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">399</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Volkan ACIMIŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H68</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">400</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Evren ÖZELSANCAK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H69</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">401</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">İbrahim Münip BAYRAM</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H70</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">402</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ruhi YÜKSEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H71</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">403</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Serdal GÜLAŞI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H72</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">404</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Muti Tamer SAYILGAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H73</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">405</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Enver LEVENDELİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H74*</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">406</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Bülent RAHATLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H75</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">407</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet ESKİLER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H76</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">408</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ecevit(Soner) MATPAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H77</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">409</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ergin ERDEK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H78</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">410</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Dinçer TURAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H79</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">411</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Serkan İÇME</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H80</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">412</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet TANGERLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H81</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">413</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Yusuf ŞİMŞEK</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H82</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">414</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Gürkan BUGAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H83</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">415</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Erdal GÜLLENDİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H84</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">416</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Alper UZKANBAŞ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H85</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">417</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Fırat IRGA</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H86</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">418</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Serdar SEZER</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H87</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">419</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Özer URASLI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H88</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">420</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Adem SÜREKLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">H89</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">421</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hakan SEVENLİ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K30</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">422</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali Esat FORLAR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K31</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">423</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Evin YASAVUR</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K32</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">424</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Hakan HALLAÇOĞLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K33</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">425</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ahmet GÖZEL</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K34</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Engin SÜRGEÇ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K35</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Seyidhan DAĞSUYU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K36</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Serpil TOPALUĞURLU</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K37</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Ali ÖZMİS</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K38</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Taner İNCAKALAN</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K39</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Engin KORKMAZ</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K40</div>
          </td>
        </tr>

        <tr>
          <td data-label="#">
            <div class="mavi">426</div>
          </td>
          <td data-label="Ad Soyad" class="alsa">
            <div id="number7">Esat KARSLI</div>
          </td>
          <td data-label="" class="alsa">
            <div id="number8">K41</div>
          </td>
        </tr>


        </tbody>
      </table>
    </div>
  </div>
</div>
