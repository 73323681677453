<div class="request-form">
    <div class="container-fluid">
        <div class="row">
            <div class="right-div col-sm-12 col-md-12 col-lg-4">
                <div class="tab-button">
                    <div class="col-4">BİRİM</div>
                    <div class="col-4 bid">ALIŞ</div>
                    <div class="col-4 ask">SATIŞ</div>
                </div>
                <div class="overflow-box">
                    <div class="grid-box" *ngFor="let data of dataList4 ; let i=index; trackBy : trackByPrice">
                        <div class="grid-card" [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
                            <div class="grid-text col-4">
                                {{data.Code}} <br>
                                <div class="grid-date"> {{data.Time | date:'h:mm:ss'}}</div>
                            </div>
                            <div class="price col-4">
                                {{data.Bid | number:'1.2-2'}}
                            </div>
                            <span class="rate-icon" *ngIf="data.askPercentChange>0" style="color: #3a9d37;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-up"></i>
              </span>
                            <span class="rate-icon" *ngIf="data.askPercentChange<0" style="color: #f25961;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-down"></i>
              </span>
                            <span class="rate-icon" *ngIf="data.askPercentChange===0 || !data.askPercentChange" style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
                            <div class="price col-4">
                                {{data.Ask | number:'1.2-2'}} <br>
                                <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                                <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange" class="grid-all col-sm-3 col-md-3 col-lg-3" style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="left-div col-sm-12 col-md-12 col-lg-8">
                <app-carousel [slides]="slides" [animationType]="animationType"></app-carousel>
            </div>
        </div>
    </div>
</div>
<div class="phone-content text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <h3><strong>ŞAHAP KUYUMCU FAALİYET KONULARI</strong></h3>
                        <br>
                        <p><span>- Uluslararası Sertifikalı Külçe Altın<br>
- Uluslararası Sertifikalı Gümüş<br>
- Granül Gümüş<br>
- Platin<br>
- Hurda Altın Alımı<br>
- Raporluk Altın Alımı<br>
- Takoz Değişimi<br>
-Standart Dışı Altın, Gümüş, Platin vb. Madenlerin İthalat ve İhracatını Yapmak
            </span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="phone-content">
    <div class="row">
        <div class="subrow">
            <!-- feature item -->
            <div class="col-lg-6 col-md-12 align-self-center text-center">
                <div class=" phone-img"></div>
            </div>
            <!-- feature item -->
            <div class="text-subcontent col-lg-6 col-md-12 align-self-center ">
                <div class="head">
                    <div class="number-box">
                        <i class="fa fa-mobile-alt"></i>
                    </div>
                    <h2>FİYATLARIMIZI MOBİL UYGULAMAMIZDAN TAKİP EDEBİLİRSİNİZ</h2>
                </div>
                <div class="body">
                    <p>Fiyatlarımız artık her zaman ve her yerde yanınızda. <br> Mobil uygulamamız üzerinden güncel fiyatlarımızı <br> anlık grafikler ile takip edebilir ve karlılığınızı <br> doğru pozisyonu yakalayarak en üst düzeye <br> taşıyabilirsiniz.
                        <br><br> Mobil Uygulamamızda Bulunan Bazı Özellikler:
                    </p>
                    <ul>
                        <li>
                            <p>Portföy</p>
                        </li>
                        <li>
                            <p>Çevirici</p>
                        </li>
                        <li>
                            <p>Grafikler</p>
                        </li>
                        <li>
                            <p>Favori</p>
                        </li>
                    </ul>
                    <div class="app-button">
                        <div class="col-6" style="max-width: 200px;">
                            <a type="button" href="https://apps.apple.com/tr/app/%C5%9Fahap-kuyumculuk/id1557900902?l=tr" target="_blank">
                                <div>
                                    APP STORE
                                </div>
                            </a>
                        </div>
                        <div class="col-6" style="max-width: 200px;">
                            <a type="button" href="https://play.google.com/store/apps/details?id=com.aifasoft.sahapkuyumculuk" target="_blank">
                                <div>PLAY STORE</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="phone-content text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>
                    <span>Adana'da Milli Mücahit olan Şeyh Cemil Nardalı Efendinin birinci oğlu olan Mesut Nardalı, Yıldız Sarrafiye Evini 1930 yılında kurmuştur.
                1957 yılında Mesut Nardalı’nın oğlu Şahap Nardalı, Yıldız Sarrafiye Evi'nin ünvanını Şahap Kuyumcu olarak değiştirmiştir.
                Şahap Kuyumcu'yu da 1957-1995 yıllarında oğlu Volkan Nardalı’ya devretmiştir, Şahap Kuyumcu geçmişten bugüne nesilden nesile aktarılan başarısıyla günümüze kadar gelmiş ve daha sonraki nesillere aktarılarak başarılarına devam edecektir.
                <br>
                Şahap kuyumcu, altın sektörünün dinamiklerini en iyi şekilde analiz ederek sektörde hızla yükselmiş, güvenilir kurum kimliğini koruyarak, müşterilerinin ihtiyaç, talep ve beklentilerini ilkeleri ve etik ticari koşullar doğrultusunda karşılamıştır.
                Şahap Kuyumcu, 1930 yılından günümüze kadar istihdamın artmasına katkı sağlayarak,çevresine faydalı bir kurum olmaya devam edecektir
              </span>
                </p>
            </div>
        </div>
    </div>
</div>