import {Injectable} from '@angular/core';
import {ROUTING} from '../shared/routing';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  menus = [
    {
      title: 'İşlemler',
      type: 'header'
    },
    {
      title: 'Sayfalar',
      icon: 'fa fa-edit',
      active: false,
      type: 'dropdown',
      badge: {
        text: 'New ',
        class: 'badge-warning'
      },
      submenus: [
        {
          title: 'Anasayfa',
          routerLink: ROUTING.HOME,
          badge: {
            text: 'Pro ',
            class: 'badge-success'
          }
        },
        {
          title: 'Canlı Piyasalar',
          routerLink: ROUTING.CURRENCY
        }
      ]
    },
    {
      title: 'Doküman',
      icon: 'fa fa-book',
      active: false,
      type: 'simple',
      badge: {
        text: 'Beta',
        class: 'badge-primary'
      },
    },
    {
      title: 'Takvim',
      icon: 'fa fa-calendar',
      active: false,
      type: 'simple'
    },
    {
      title: 'IK',
      icon: 'fa fa-edit',
      active: false,
      badge: {
        text: 'Pro ',
        class: 'badge-success'
      },
      type: 'dropdown',
      submenus: [
        {
          title: 'Çalışanlar',
          routerLink: ROUTING.GOLD,
        },
        {
          title: 'Ekipler',
          routerLink: ROUTING.INFORMATION
        }
      ]
    },
  ];

  constructor() {
  }

  _hasBackgroundImage = true;

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }

  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }
}
