<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand col-sm-2 col-md-2 col-lg-2">
    <img (click)="route('home')" style="width: 100px;" src="../../../assets/img/SAHAP.png">
  </a>
  <button class="navbar-toggler" type="button" (click)="navbarOpen = !navbarOpen" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-bars"></i>
  </button>
  <div class="collapse navbar-collapse col-sm-12 col-md-12 col-lg-8 show" id="navbarSupportedContent"
       [ngClass]="{ 'show': navbarOpen }" (mouseout)="navbarOpen = false">
    <div class="navbar-nav col-12 col-sm-12 col-md-12 col-lg-12">
      <div id="home" class="nav-item">
        <div class="text" (click)="route('home')">
          <a class="nav-text"> ANASAYFA </a>
        </div>
      </div>
      <div class="nav-item">
        <div class="text" (click)="route('gold')">
          <a class="nav-text"> FİYAT EKRANI </a>
        </div>
      </div>
      <div class="nav-item ">
        <div class="text" (click)="route('patent')">
          <a class="nav-text"> PATENT LİSTESİ </a>
        </div>
      </div>
      <div class="nav-item ">
        <div class="text" (click)="route('shop')">
          <a class="nav-text"> ÜRÜNLERİMİZ </a>
        </div>
      </div>
      <div id="info" class="nav-item">
        <div class="text" (click)="route('info')">
          <a class="nav-text"> HAKKIMIZDA </a>
        </div>
      </div>
      <div id="comm" class="nav-item">
        <div class="text" (click)="route('comm')">
          <a class="nav-text"> İLETİŞİM </a>
        </div>
      </div>
      <div class="nav-item">
        <div class="text" (click)="route('kadsis')">
          <a class="nav-text"> KAD-SİS </a>
        </div>
      </div>
      <div class="nav-item">
        <div class="text">
          <a class="nav-text animated flash infinite" href="https://e-sahapkuyumcu.com" target="_blank" style="color: rgb(255, 0, 0);"> E-TİCARET </a>
        </div>
      </div>
    </div>
  </div>
  <div class="date-cont col-lg-2">
    <div class="nav-date col-12" tabindex="-1" aria-disabled="true">
           <span class="nav-hour">
      {{time | date:'H:mm: ' : 'GMT +3' }}
    </span>
      <span class="nav-seconds">
      {{time | date:'ss ' : 'GMT +3' }}
    </span>
    </div>
  </div>
</nav>
