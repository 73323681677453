<div class="content">
  <div class="who">
    <div class="wyre-container col-lg-4 col-md-2 ">
      <div class="footer-logo"></div>
    </div>
    <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
      <div class="who-content">
        <h3>Adres</h3>
        Tepebağ Mahallesi Çakmak Caddesi Hilalhan İş Merkezi No2/Y Seyhan / Adana
      </div>
    </div>
    <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
      <div class="who-content">
        <h3>Telefon</h3>
        <a href="tel:03223600060">0322 360 0060</a>
      </div>
    </div>
    <div class="wyre-container col-lg-12 col-md-12 col-sm-12">
      Powered by <a style="margin-left: 5px" href="http://www.aifasoft.com/" target="_blank">AIFASOFT</a>
    </div>
  </div>
</div>
